import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { validationError } from './validation-error';
import FormFieldGrid from './form-field-grid';
import { meta, input } from '@data-driven-forms/common/prop-types-templates';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styles } from './styles';

const TextField = styled(MuiTextField)(() => (styles));

const DatePicker = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormFieldGridProps = {},
    DatePickerProps = {},
  } = useFieldApi(props);
  const invalid = validationError(meta, validateOnMount);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <MUIDatePicker
        renderInput={(params) => {
          return (
          <TextField
            fullWidth
            variant='filled'
            margin="none"
            label={label}
            placeholder={placeholder}
            required={isRequired}
            helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
            {...params}
            error={!!invalid}
            name={input.name}
          />)
        }}
        minDate={dayjs(DatePickerProps.minDate||'1900-01-01T00:00:00')}
        maxDate={dayjs(DatePickerProps.maxDate||undefined)}
/*         disableFuture={input.name !== ("PeriodLicense" || "EndDate") ? true : false}
        disablePast={input.name === ("PeriodLicense" || "EndDate") ? true : false} */
        fullWidth
        variant='filled'
        margin="none"
        label={label}
        error={!!invalid}
        helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
        placeholder={placeholder}
        required={isRequired}
        disabled={isDisabled || isReadOnly}
        readOnly={isReadOnly}
        onError={(error, value) => {
          input.onBlur();
          input.onFocus();
        }} 
        {...input}
        value={input.value ? input.value:null}
        {...DatePickerProps}
      />
    </FormFieldGrid>
  );
};

DatePicker.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  DatePickerProps: PropTypes.object,
};

export default DatePicker;
